// .modal-overlay {
//     max-width: 1200px;
// }

.modal-content-editar-aula  {
    display: flex;
    flex: 1;
    flex-direction: column;
    // min-width: 30em;
    max-width: 700px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
    max-height: 85vh;
    text-transform: uppercase;
    padding: 1em 1em 1.5em 1em;
    // padding-bottom: 2.5em;
    cursor: default;
}

.modal-container-Editar-Aula {
    flex: 1;
    justify-content: center;
    align-items: center;
    // overflow-y: scroll;
    padding: 0em 1em 0em 1em;

    h2 {
        text-align: center;
        color: #313131;
        opacity: 0.7;
        max-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
    }

    

    .dados-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 0.8em;
        gap: 0.5em;

        label {
            color: #313131;
            opacity: 0.7;
            max-height: 40px;
            margin-bottom: 0;
            margin-top: 0;
            font-size: x-small;
        }

        input[type="text"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
        }

        input:disabled {
            cursor: not-allowed;
        }


        input[type="number"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
            
        }

        input[type="date"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 2px solid rgba(0, 0, 0, 0.2);
            font-size: medium;
        }

        textarea {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
            resize: none;
        }

        .aula-container {
            display: flex;
            flex-direction: column;
        }
        
        textarea:focus {
            border: 1px solid #3aabe9;
            outline: none;
        }

        
    }

    .turma-data-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5em
    }

    .data-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    input[type="number"]:focus {
        border: 1px solid #3aabe9;
        outline: none;
    }

    .aula-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 5.5em;
    }

    .turma-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        // width: 5.5em;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1em 1em 0em 1em;
    }

    button {
        padding: 0.7em 0.8em;
        border-radius: 5px;
        font-weight: bold;
        // box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
        cursor: pointer;
    }
    
    .cancel-button {
        border: 1px solid #5f6267;
        background-color: #5f6267;
        color: white;
    }

    .cancel-button:hover {
        border: 1px solid #7c7d80;
        background-color: #7c7d80;
    }

    .confirm-button {
        background-color: #2d78a0;
        border: 1px solid #2d78a0;
        color: white;
    }

    .confirm-button:hover {
        background-color: #3c92c1;
        border: 1px solid #3c92c1;
        color: white;
    }
}

.modal-container-Editar-Faltas {
    flex: 1;
    justify-content: center;
    align-items: center;
    // overflow-y: scroll;
    padding: 0em 1em 0em 1em;

    #boxAulas {
        padding: 0.5em 0.8em;
        border-radius: 5px;
        border: 1px solid rgba(36, 74, 105, 0.4);
        font-size: small;
        width: 3em;
        text-align: center;
        color: #313131
    }

    #boxAulas:focus {
        border: 1px solid #30aff4;
        outline: none;
        color: #166189
    }

    #faltasField {
        white-space: nowrap;
    }

    .alunos-container {
        max-height: 20em;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #alunoField {
        font-size: small;
    }

    #matriculaField {
        opacity: 0.7;
        font-size: x-small;
    }

    th {
        color: #313131;
        max-height: 32px;
        margin-bottom: 0;
        margin-top: 0;
    }

    hr {
        width: 100%;
        color: #313131;
        opacity: 0.4;
    }

    h2 {
        text-align: center;
        color: #313131;
        opacity: 0.7;
        max-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
    }

    #loadingIcon {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        // margin-top: 0.2em;
        // margin-bottom: 0.8em;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow: hidden;

        tr:nth-child(even) {
            background-color: #f2f2f29b;
        }

        td#registro {
            text-align: center;
            opacity: 0.5;
        }

        th {
            font-size: x-small;
            background-color: #2d78a024;
            border: 1px solid #16618937;
        }

        th, td {
            text-align: left;
            padding: 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        #boxFaltas {
            display: flex;
            flex: 1;
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(36, 74, 105, 0.4);
            font-size: small;
            width: 7em;
            text-align: center;
            color: #313131
        }


        #boxFaltas:focus {
            border: 1px solid #30aff4;
            outline: none;
            color: #166189
        }
    }

    .dados-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 0.8em;
        gap: 0.5em;

        label {
            color: #313131;
            opacity: 0.7;
            max-height: 40px;
            margin-bottom: 0;
            margin-top: 0;
            font-size: x-small;
        }

        input[type="text"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
        }

        input:disabled {
            cursor: not-allowed;
        }


        input[type="number"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
            
        }

        input[type="date"] {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 2px solid rgba(0, 0, 0, 0.2);
            font-size: medium;
        }

        textarea {
            padding: 0.5em 0.8em;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: small;
            resize: none;
        }

        .aula-container {
            display: flex;
            flex-direction: column;
        }
        
        textarea:focus {
            border: 1px solid #3aabe9;
            outline: none;
        }

        
    }

    .turma-data-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5em
    }

    .data-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    input[type="number"]:focus {
        border: 1px solid #3aabe9;
        outline: none;
    }

    .aula-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 5.5em;
    }

    .turma-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        // width: 5.5em;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1em 1em 0em 1em;
    }

    button {
        padding: 0.7em 0.8em;
        border-radius: 5px;
        font-weight: bold;
        // box-shadow: 0px 3px 15px 0px rgba(117, 117, 117, 0.63), 5px 10px 16px -6px rgba(205, 205, 205, 0.63);
        cursor: pointer;
    }
    
    .cancel-button {
        border: 1px solid #5f6267;
        background-color: #5f6267;
        color: white;
    }

    .cancel-button:hover {
        border: 1px solid #7c7d80;
        background-color: #7c7d80;
    }

    .confirm-button {
        background-color: #2d78a0;
        border: 1px solid #2d78a0;
        color: white;
    }

    .confirm-button:hover {
        background-color: #3c92c1;
        border: 1px solid #3c92c1;
        color: white;
    }
}