.section-consultarAulas{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-page {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0px;
        color: #2d78a0;
        margin: 0em 0em 0em 0em;
    }

    div.containerTitleConsultarDiario {
        margin: 0.5em 0em 0.5em 3.5em; 
    }

    #container-tabela {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        max-height: 80%;
    }

}

p.consultarDiarioTitle {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    color: #2d78a0;
    margin: 0em 0em 0.5em 0em;
}