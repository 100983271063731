.DiarioSearchContainer {
  display: flex;
  align-items: center;
  align-self: center;
  background-color: #324354;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 70vw;
  height: 50px;
  margin-top: 5px;
  box-shadow: 0px 10px 13px -7px rgba(219, 219, 219, 0.536), 7px 15px 16px -6px rgba(230, 230, 230, 0.629);
}
.DiarioSearchContainer select {
  color: rgba(50, 67, 84, 0.8784313725);
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px 0px 20px 2em;
  border: 1px solid rgba(174, 174, 174, 0.6235294118);
  border-radius: 5px;
  outline: none;
  padding: 4px;
  max-width: 15em;
}
.DiarioSearchContainer #labelInicioDiarioData {
  color: white;
  margin-left: 15px;
  margin-right: 6px;
}
.DiarioSearchContainer #labelFimDiarioData {
  margin-left: 15px;
  margin-right: 6px;
  color: white;
}
.DiarioSearchContainer input {
  border: 1px solid rgba(174, 174, 174, 0.6235294118);
  border-radius: 5px;
  outline: none;
  padding: 3px;
  color: #324354;
}/*# sourceMappingURL=DiarioPainelPesquisa.css.map */