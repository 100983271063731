.modal-content-editar-aula {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 700px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  box-shadow: 12px 14px 38px -7px rgba(0, 0, 0, 0.38);
  max-height: 85vh;
  text-transform: uppercase;
  padding: 1em 1em 1.5em 1em;
  cursor: default;
}

.modal-container-Editar-Aula {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0em 1em 0em 1em;
}
.modal-container-Editar-Aula h2 {
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-container-Editar-Aula .dados-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.8em;
  gap: 0.5em;
}
.modal-container-Editar-Aula .dados-container label {
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: x-small;
}
.modal-container-Editar-Aula .dados-container input[type=text] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
}
.modal-container-Editar-Aula .dados-container input:disabled {
  cursor: not-allowed;
}
.modal-container-Editar-Aula .dados-container input[type=number] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
}
.modal-container-Editar-Aula .dados-container input[type=date] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  font-size: medium;
}
.modal-container-Editar-Aula .dados-container textarea {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
  resize: none;
}
.modal-container-Editar-Aula .dados-container .aula-container {
  display: flex;
  flex-direction: column;
}
.modal-container-Editar-Aula .dados-container textarea:focus {
  border: 1px solid #3aabe9;
  outline: none;
}
.modal-container-Editar-Aula .turma-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
}
.modal-container-Editar-Aula .data-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.modal-container-Editar-Aula input[type=number]:focus {
  border: 1px solid #3aabe9;
  outline: none;
}
.modal-container-Editar-Aula .aula-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5.5em;
}
.modal-container-Editar-Aula .turma-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.modal-container-Editar-Aula .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 1em 0em 1em;
}
.modal-container-Editar-Aula button {
  padding: 0.7em 0.8em;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.modal-container-Editar-Aula .cancel-button {
  border: 1px solid #5f6267;
  background-color: #5f6267;
  color: white;
}
.modal-container-Editar-Aula .cancel-button:hover {
  border: 1px solid #7c7d80;
  background-color: #7c7d80;
}
.modal-container-Editar-Aula .confirm-button {
  background-color: #2d78a0;
  border: 1px solid #2d78a0;
  color: white;
}
.modal-container-Editar-Aula .confirm-button:hover {
  background-color: #3c92c1;
  border: 1px solid #3c92c1;
  color: white;
}

.modal-container-Editar-Faltas {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0em 1em 0em 1em;
}
.modal-container-Editar-Faltas #boxAulas {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(36, 74, 105, 0.4);
  font-size: small;
  width: 3em;
  text-align: center;
  color: #313131;
}
.modal-container-Editar-Faltas #boxAulas:focus {
  border: 1px solid #30aff4;
  outline: none;
  color: #166189;
}
.modal-container-Editar-Faltas #faltasField {
  white-space: nowrap;
}
.modal-container-Editar-Faltas .alunos-container {
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modal-container-Editar-Faltas #alunoField {
  font-size: small;
}
.modal-container-Editar-Faltas #matriculaField {
  opacity: 0.7;
  font-size: x-small;
}
.modal-container-Editar-Faltas th {
  color: #313131;
  max-height: 32px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-container-Editar-Faltas hr {
  width: 100%;
  color: #313131;
  opacity: 0.4;
}
.modal-container-Editar-Faltas h2 {
  text-align: center;
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
}
.modal-container-Editar-Faltas #loadingIcon {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.modal-container-Editar-Faltas table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.modal-container-Editar-Faltas table tr:nth-child(even) {
  background-color: rgba(242, 242, 242, 0.6078431373);
}
.modal-container-Editar-Faltas table td#registro {
  text-align: center;
  opacity: 0.5;
}
.modal-container-Editar-Faltas table th {
  font-size: x-small;
  background-color: rgba(45, 120, 160, 0.1411764706);
  border: 1px solid rgba(22, 97, 137, 0.2156862745);
}
.modal-container-Editar-Faltas table th, .modal-container-Editar-Faltas table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.modal-container-Editar-Faltas table #boxFaltas {
  display: flex;
  flex: 1;
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(36, 74, 105, 0.4);
  font-size: small;
  width: 7em;
  text-align: center;
  color: #313131;
}
.modal-container-Editar-Faltas table #boxFaltas:focus {
  border: 1px solid #30aff4;
  outline: none;
  color: #166189;
}
.modal-container-Editar-Faltas .dados-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.8em;
  gap: 0.5em;
}
.modal-container-Editar-Faltas .dados-container label {
  color: #313131;
  opacity: 0.7;
  max-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: x-small;
}
.modal-container-Editar-Faltas .dados-container input[type=text] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
}
.modal-container-Editar-Faltas .dados-container input:disabled {
  cursor: not-allowed;
}
.modal-container-Editar-Faltas .dados-container input[type=number] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
}
.modal-container-Editar-Faltas .dados-container input[type=date] {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  font-size: medium;
}
.modal-container-Editar-Faltas .dados-container textarea {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: small;
  resize: none;
}
.modal-container-Editar-Faltas .dados-container .aula-container {
  display: flex;
  flex-direction: column;
}
.modal-container-Editar-Faltas .dados-container textarea:focus {
  border: 1px solid #3aabe9;
  outline: none;
}
.modal-container-Editar-Faltas .turma-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
}
.modal-container-Editar-Faltas .data-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.modal-container-Editar-Faltas input[type=number]:focus {
  border: 1px solid #3aabe9;
  outline: none;
}
.modal-container-Editar-Faltas .aula-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5.5em;
}
.modal-container-Editar-Faltas .turma-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.modal-container-Editar-Faltas .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 1em 0em 1em;
}
.modal-container-Editar-Faltas button {
  padding: 0.7em 0.8em;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.modal-container-Editar-Faltas .cancel-button {
  border: 1px solid #5f6267;
  background-color: #5f6267;
  color: white;
}
.modal-container-Editar-Faltas .cancel-button:hover {
  border: 1px solid #7c7d80;
  background-color: #7c7d80;
}
.modal-container-Editar-Faltas .confirm-button {
  background-color: #2d78a0;
  border: 1px solid #2d78a0;
  color: white;
}
.modal-container-Editar-Faltas .confirm-button:hover {
  background-color: #3c92c1;
  border: 1px solid #3c92c1;
  color: white;
}/*# sourceMappingURL=ModalEditarAula.css.map */